import Signin from "components/Signin";
import Layout from "components/auth/Layout";

const SigninPage = () => (
  <Layout>
    <Signin />
  </Layout>
);

export default SigninPage;
