import { useState } from "react";
import { Alert, Button, Col, Form, Image, Input, message, Row } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import styled from "styled-components";

const FormItem = Form.Item;

const Content = styled.div`
  max-width: 400px;
  z-index: 2;
  min-width: 300px;
`;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
    number: "${label} is not a validate number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const PageWrapper = styled(Content)`
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "";
  }
`;

const Signin = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const router = useRouter();

  const login = async ({ username, password }) => {
    try {
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (response.ok) {
        const loginResponse = await response.json();
        if (loginResponse.status) {
          const user = loginResponse.user;
          if (user.role === "USER") {
            console.log("GO TO CHECKIN");
            window.location.href = "/checkin";
            // await router.push("/checkin");
          } else {
            window.location.href = "/admin";
            // await router.push("/admin");
          }
        } else {
          setError("มีบางอย่างผิดพลาดกรุุณาลองใหม่");
        }
      } else {
        if (response.status === 401) {
          setError("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้องกรุณาลองใหม่");
        } else {
          setError("มีบางอย่างผิดพลาดกรุุณาลองใหม่");
        }
      }
    } catch (e) {
      setError("มีบางอย่างผิดพลาดกรุุณาลองใหม่");
      console.log(e);
    }
  };
  return (
    <PageWrapper>
      <div className="mb-2">
        <a className="brand mr-0">
          <Row gutter={[16, 16]} className="text-center mt-5" align="middle">
            <Col span="24">
              <Image width={100} preview={false} src="/logo1.jpg" className="pr-2"/>
              <Image width={110} preview={false} src="/logo2.jpg" />
            </Col>
          </Row>
          {/* <Triangle size={32} strokeWidth={1} /> */}
        </a>
        <h4 className="text-center mt-3 text-primary">ระบบฐานข้อมูลผู้ดูแลศูนย์</h4>
        <h4 className="text-center mt-1 text-primary">USO Network</h4>
      </div>
      {error ? <Alert className="mb-3" message={error} type="error" /> : null}
      <Form
        className="my-5"
        layout="vertical text-secondary"
        validateMessages={validateMessages}
        form={form}
        onFinish={login}
        onChange={() => {
          setError("");
        }}
      >
        ชื่อผู้ใช้
        <FormItem
        className=""
          name={["username"]}
          rules={[{ required: true, message: "กรุณากรอกชื่อผู้ใช้" }]}
        >
          <Input type="text" placeholder="" />
        </FormItem>
        รหัสผ่าน
        <FormItem
          name={["password"]}
          rules={[{ required: true, message: "กรุณากรอกรหัสผ่าน" }]}
        >
          <Input.Password
            placeholder="กรอกรหัสผ่านใหม่"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </FormItem>
        <FormItem className="mt-4">
          <Button ghost type="primary" htmlType="submit" block className="mt-3">
            เข้าสู่ระบบ
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = '/login-register'
            }}
            block
            className="mt-3 shadow-0"
          >
            สมัครเจ้าหน้าที่ดูแล้วศูนย์อินเตอร์เน็ต USO NET
          </Button>
        </FormItem>
      </Form>
    </PageWrapper>
  );
};

export default Signin;
